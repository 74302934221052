import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import Blog from "components/system/blog"
import Pager from "components/system/pager"
import TaxonomyTerm from "components/system/taxonomy-term"
import "scss/blog.scss"
import { LocalizedLink } from "gatsby-theme-i18n"
import Anchor from "../components/helpers/anchor"
import loup from "../images/loup.webp"
import vague from "../images/vague.webp"

const BlogTemplate = ({
  data: {
    allTaxonomyTermCategories,
    taxonomyTermCategories,
    allTaxonomyTermTag,
    taxonomyTermTag,
    allNodeBlog,
    nodeBlogByTaxonomyTermCategories,
    nodeBlogByTaxonomyTermTag,
  },
  pageContext: { ...pager },
}) => {
  const blog =
    (taxonomyTermCategories && nodeBlogByTaxonomyTermCategories.edges) ||
    (taxonomyTermTag && nodeBlogByTaxonomyTermTag.edges) ||
    allNodeBlog.edges

  const metadata = {
    url: (taxonomyTermCategories && "") || "/blog",
    title: "Blog",
  }

  const [searchText, setSearchText] = useState("")

  return (
    <Layout>
      <SEO {...metadata} />
      <div className="header-blue">
        <div className="container">
          <div className="blog__header">
            <h1>
              <span>Blog</span>
            </h1>
            <span
              className="overline"
              style={{
                backgroundImage: `url(${vague})`,
              }}
            ></span>
          </div>
          {taxonomyTermCategories && (
            <div className="breadcrumb__blog__mobile case__breadcrumb__blog">
              <div>
                <LocalizedLink to="/blog">Blog</LocalizedLink>
                <span className="case__separator"> > </span>
                <Anchor href={taxonomyTermCategories?.path?.alias}>
                  {taxonomyTermCategories?.name}
                </Anchor>
              </div>
            </div>
          )}
          {taxonomyTermTag && (
            <div className="breadcrumb__blog__mobile case__breadcrumb__blog">
              <LocalizedLink to="/blog">Blog</LocalizedLink>
              <span className="case__separator"> > </span>
              <Anchor href={taxonomyTermTag?.path?.alias}>
                {taxonomyTermTag?.name}
              </Anchor>
            </div>
          )}
          <div className="listing-blog">
            <div className="listing-blog__filtres">
              <form action="/search" method="get">
                <input
                  type="text"
                  name="q"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Découvrez des actualités, des articles..."
                />
                <button
                  type="submit"
                  className="icon-loup"
                  aria-label="icon-loup"
                  style={{
                    backgroundImage: `url(${loup})`,
                  }}
                />
              </form>
              <div className="listing-blog__filtres--title">Categorie</div>
              {allTaxonomyTermCategories.edges.map(
                ({ node: { id, name, path } }) => (
                  <TaxonomyTerm key={id} name={name} path={path} />
                )
              )}
            </div>
            <div className="listing-blog__articles">
              {taxonomyTermCategories && (
                <div className="breadcrumb__blog__mobile case__breadcrumb__blog">
                  <div>
                    <LocalizedLink to="/blog">Blog</LocalizedLink>
                    <span className="case__separator"> > </span>
                    <Anchor href={taxonomyTermCategories?.path?.alias}>
                      {taxonomyTermCategories?.name}
                    </Anchor>
                  </div>
                </div>
              )}
              {taxonomyTermTag && (
                <div className="breadcrumb__blog__mobile case__breadcrumb__blog">
                  <LocalizedLink to="/blog">Blog</LocalizedLink>
                  <span className="case__separator"> > </span>
                  <Anchor href={taxonomyTermTag?.path?.alias}>
                    {taxonomyTermTag?.name}
                  </Anchor>
                </div>
              )}
              {blog?.map((node) => {
                node = node?.node || node
                return <Blog key={node.id} {...node} />
              })}
            </div>
          </div>
          <Pager
            path={
              taxonomyTermCategories?.path?.alias ||
              taxonomyTermTag?.path?.alias
            }
            url={`/page/`}
            currentPage={pager.currentPage}
            numPages={pager.numPages}
            ellipses={{
              previous: pager.currentPage > 3,
              next: pager.currentPage < pager.numPages - 2,
            }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query BlogTemplateQuery(
    $entityID: String = ""
    $locale: String = "fr"
    $skip: Int!
    $limit: Int!
  ) {
    allTaxonomyTermCategories(filter: { langcode: { eq: $locale } }) {
      edges {
        node {
          ...TaxonomyTermCategories
        }
      }
    }
    taxonomyTermCategories(id: { eq: $entityID }) {
      ...TaxonomyTermCategories
    }
    allTaxonomyTermTag(filter: { langcode: { eq: $locale } }) {
      edges {
        node {
          ...TaxonomyTermTag
        }
      }
    }
    taxonomyTermTag(id: { eq: $entityID }) {
      ...TaxonomyTermTag
    }
    allNodeBlog(
      filter: { langcode: { eq: $locale } }
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: created }
    ) {
      edges {
        node {
          ...Blog
        }
      }
    }
    nodeBlogByTaxonomyTermCategories: allNodeBlog(
      sort: { order: DESC, fields: created }
      filter: {
        langcode: { eq: $locale }
        relationships: {
          field_categorie: { elemMatch: { id: { eq: $entityID } } }
        }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          ...Blog
        }
      }
    }
    nodeBlogByTaxonomyTermTag: allNodeBlog(
      sort: { order: DESC, fields: created }
      filter: {
        langcode: { eq: $locale }
        relationships: { field_tag: { elemMatch: { id: { eq: $entityID } } } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...Blog
        }
      }
    }
  }
`
